body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.legenda{
  display: flex;
  flex-direction: column;
  align-items: baseline;
  height: 50px;
  justify-content: space-around;
  font-size: 12px;
}
.tempoClinica{
  background-color: #427bbe;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
.tempoLivre{
  background-color: #ed7d31;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  float: left;
  margin-right: 5px;
}
