@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/list/main.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.title-event{
  padding: 5px;
  color: white;
  font-weight: bold;
  min-height: 60px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}
p{
  text-align: justify;
}
.margin-title{
  margin-top: 30px;
  margin-bottom: 30px;
}
.message-course{

}
.padding-40{
  padding: 40px;
}

a.fc-day-grid-event.fc-h-event:hover {
  width: max-content;
  z-index: 20;
}

.box-legenda{
  max-height: 280px;
  overflow: hidden;
  box-shadow: 0px -20px 0px 11px white;
}

.click-show{
  position: absolute;
    background: white;
    border: 1px solid black;
    transition: 0.2s all;
    color: black;
    width: 100px;
    height: auto;
    display: block;
    z-index: 999;
    top: 258px;
    left: 187px;
    cursor: pointer;
}
.click-show:hover{
  background-color: black;
  color: white;
}